























import Vue from "vue";
import { firestore, callableFunctions } from "@/firebase";
import orderBy from "lodash/orderBy";

import ActionButton from "@/components/ActionButton.vue";
import ListFirestore from "@/components/ListFirestore.vue";
import BackupListItem from "@/components/BackupListItem.vue";

const tenMinutes = 10 /* min */ * 60 /* sec */ * 1000; /* ms */

export default Vue.extend({
  name: "Backups",
  components: {
    ListFirestore,
    ActionButton,
    BackupListItem
  },
  data: () => ({
    isRunningBackup: false,
    backupError: null as Error | null,
    mostRecentBackupDate: null as Date | null
  }),
  computed: {
    manualBackupDisabled(): boolean {
      console.log("mostRecentBackupDate", this.mostRecentBackupDate);
      if (this.mostRecentBackupDate) {
        const now = new Date().getTime();
        console.log(now - this.mostRecentBackupDate.getTime(), tenMinutes);
        if (now - this.mostRecentBackupDate.getTime() > tenMinutes) {
          return false;
        }
      }
      return false;
    }
  },
  methods: {
    getBackupCollection(): FirestoreQuery {
      return firestore
        .collection("global-admin")
        .doc("backups")
        .collection("backups")
        .orderBy("createdAt", "desc");
    },
    orderList(items: Record<string, BackupDocument>): BackupDocument[] {
      const itemsOrderedByCreationDate = orderBy(Object.values(items), "createdAt", "desc");
      if (itemsOrderedByCreationDate.length > 0 && itemsOrderedByCreationDate[0]) {
        this.mostRecentBackupDate = itemsOrderedByCreationDate[0]?.createdAt?.toDate();
      }
      return itemsOrderedByCreationDate;
    },
    async runBackup(): Promise<void> {
      if (!this.isRunningBackup) {
        this.isRunningBackup = true;
        this.backupError = null;
        try {
          const response = await callableFunctions.runFirestoreBackup();
          console.log(response);
        } catch (error) {
          console.error(error);
          this.backupError = error as Error;
        }
        this.isRunningBackup = false;
      }
    }
  }
});
