





















import Vue, { PropType } from "vue";
import { callableFunctions } from "@/firebase";

import UserName from "./UserName.vue";

export default Vue.extend({
  name: "BackupListItem",
  components: {
    UserName
  },
  props: {
    backup: { type: Object as PropType<BackupDocument & { id: string }>, required: true }
  },
  data: () => ({
    fetchingSize: false
  }),
  computed: {
    backupRunning() {
      if (
        this.backup &&
        this.backup.createdAt &&
        new Date().getTime() - this.backup.createdAt.toDate().getTime() > 10 * 60 * 1000
      ) {
        return false;
      }
      return true;
    }
  },
  created() {
    if (this.backup.size === null && !this.backupRunning) {
      void this.getSizeOfBackup(this.backup.id);
    }
  },
  methods: {
    async getSizeOfBackup(backupId: string): Promise<void> {
      this.fetchingSize = true;
      try {
        const { data } = (await callableFunctions.getSizeOfBackup({ backupId })) as {
          data: { size: number };
        };
        const { size } = data;
        console.log(backupId, size);
      } catch (error) {
        console.error(error);
      }
      this.fetchingSize = false;
    }
  }
});
